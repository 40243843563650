@use '@angular/material' as mat;

@include mat.core();

// Define a dark theme
$dark-theme: mat.define-theme((color: (theme-type: dark,
        primary: mat.$blue-palette,
      ),
    ));

// Define a light theme
$light-theme: mat.define-theme((color: (theme-type: light,
        primary: mat.$blue-palette,
      ),
    ));

html {
  @include mat.all-component-typographies($dark-theme);
  @include mat.all-component-densities($dark-theme);

  // Apply the dark theme by default
  @include mat.all-component-colors($dark-theme);

  // Apply the light theme colors only when the user prefers light themes
  &[color-scheme=light] {
    @include mat.all-component-colors($light-theme);
  }
}

.mat-typography {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply tw-mb-0;
  }

  mat-card {
    mat-card-subtitle {
      @apply text-secondary;
    }

    mat-card-content {
      @apply tw-flex-grow;
    }
  }

  mat-toolbar {
    &.mat-toolbar-single-row {
      @apply tw-justify-between tw-h-auto tw-min-h-[var(--mat-toolbar-standard-height)];
    }
  }

}

body {
  .cdk-overlay-container {
    z-index: 10000; //to fix being hidden behind primeng dialog

    .cdk-overlay-backdrop {
      @apply tw-backdrop-blur-sm;
    }

    .mat-mdc-dialog-title {
      @apply surface-main-darker tw-flex-grow;
    }

    .mat-mdc-dialog-content {
      @apply tw-max-h-[85vh] surface-main tw-pb-0;
    }
  }
}

:root {

  // fix material tokens
  .mat-accent.mat-mdc-checkbox {
    --mat-checkbox-label-text-color: var(--mat-app-on-surface) !important;
  }

  --mat-sidenav-container-shape: 0; //not rounded corners for side nav
  --mat-sidenav-content-text-color: inherit; //do not override body text color
  --mat-sidenav-content-background-color: inherit; //do not override body background color
  --mat-sidenav-container-text-color: inherit; //do not override body text color
  --mat-sidenav-container-background-color: inherit; //do not override body background color
}